import React from 'react';

export type FillColor = '#303030' | '#A5A5A5';

// viewBox="0 0 4687.01 371.39"

export default function({ fillColor }: { fillColor: FillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4792.31 460.39"
      version="1.1"
      x="0px"
      y="0px"
      width="193"
      height="27.24"
    >
      <g>
        <polygon
          fill="#faa919"
          points="1000 0.76 607.1 0.76 607.1 72.26 740 72.26 740 359.56 867.2 359.56 867.2 72.26 1000 72.26 1000 0.76"
        />
        <polygon
          fill="#faa919"
          points="725.3 287.96 530.8 287.96 530.8 0.66 403.5 0.66 403.5 359.56 725.3 359.56 725.3 287.96"
        />
        <path
          fill="#faa919"
          d="M391.2,303.58c0-34.7-19.4-65.8-49.9-86.1,14.1-16.5,22.3-36.3,22.3-57.6,0-57.6-60.3-104.3-134.8-104.3H0v358.9l261.2-.1C333.4,412.28,391.2,363.58,391.2,303.58ZM210.6,192.78H115.4v-65.6h95.2c23.8,0,43,14.4,43,32.8S234.4,192.78,210.6,192.78Zm-95.2,71.5H227.1c28,0,50.8,17.6,50.8,39.3s-22.7,39.2-50.8,39.2H115.4Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#faa919"
          d="M1072.57,156.29v70.82l114.92,57.95L1072.57,343v71.18L1277.22,311V259.48Zm153,129.31v-.73l.72.37Z"
          transform="translate(0 -54.92)"
        />
      </g>
      <g id="Texts">
        <path
          fill="#faa919"
          d="M4792.31,515.31h-257V470.7h257Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#faa919"
          d="M4792.31,515.31h-257V470.7h257Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#1c991c"
          d="M1431.76,95.33a27.57,27.57,0,0,1,10-21.73q9.94-8.62,28.45-8.64t28.59,8.64a27.42,27.42,0,0,1,10.09,21.73,26.82,26.82,0,0,1-10.09,21.37q-10.1,8.53-28.59,8.53t-28.45-8.53A27,27,0,0,1,1431.76,95.33Zm72.31,318.37H1436V161h68.11Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#1c991c"
          d="M1627.07,161l2,29.2q33.63-33.87,88.29-33.87,94.74,0,96.42,90.4v167h-68.11V250q0-24.06-12.47-35.62t-40.78-11.57q-41.2,0-61.39,31.07V413.7h-68.11V161Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#1c991c"
          d="M2082.72,99.53V161h53.53v42h-53.53V344.09q0,14.49,6.86,20.91t24.53,6.42a124.68,124.68,0,0,0,23.82-2.34V413a199.21,199.21,0,0,1-44.84,5.37q-78.48,0-78.49-72.18V203h-49.89V161h49.89V99.53Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#1c991c"
          d="M2241,188.53q33.34-32.24,84.37-32.24,97,0,98.38,92.27V413.7h-68.11v-163q0-26.16-13.6-37t-39.94-10.87q-40.92,0-61.1,30.37V413.7h-68.11V54.92H2241Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#1c991c"
          d="M2611.8,418.37q-64.74,0-105-34t-40.22-90.51v-7q0-37.83,17.52-67.62t49.19-46.36Q2565,156.29,2604,156.29q62,0,95.73,32.94t33.77,93.19v22.9H2535.28q3.09,31.29,25.09,49.51t55.36,18.22q46.8,0,76.24-31.53l36.72,29.2q-18.22,22.65-48.63,35.15T2611.8,418.37Zm-8.13-216.53q-28,0-45.26,16.35t-22,45.55h129.78v-4.21Q2663.93,231,2648,216.44T2603.67,201.84Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#1c991c"
          d="M3167,335.45,3215.17,161h66.43L3198.91,413.7h-56.06l-65-173.55-63.9,173.55h-56.06l-83-252.74h66.43l49,172.62L3052.6,161h51.29Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#1c991c"
          d="M3446.35,418.37q-64.74,0-105-34t-40.22-90.51v-7q0-37.83,17.52-67.62t49.19-46.36q31.68-16.59,70.64-16.59,61.93,0,95.72,32.94T3568,282.42v22.9H3369.83q3.09,31.29,25.09,49.51t55.36,18.22q46.8,0,76.24-31.53l36.72,29.2q-18.22,22.65-48.63,35.15T3446.35,418.37Zm-8.12-216.53q-28,0-45.27,16.35t-22,45.55h129.77v-4.21q-2.25-28.48-18.22-43.09T3438.23,201.84Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#1c991c"
          d="M3740.05,418.37q-64.76,0-105-34t-40.22-90.51v-7q0-37.83,17.51-67.62t49.2-46.36q31.66-16.59,70.63-16.59,62,0,95.72,32.94t33.78,93.19v22.9H3663.53q3.07,31.29,25.08,49.51T3744,373.05q46.82,0,76.24-31.53l36.72,29.2q-18.22,22.65-48.63,35.15T3740.05,418.37Zm-8.13-216.53q-28,0-45.27,16.35t-22,45.55h129.78v-4.21q-2.25-28.48-18.22-43.09T3731.92,201.84Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#1c991c"
          d="M3887.43,285.46q0-58.39,32.51-93.78t87.18-35.39q48.2,0,77.92,28V54.92h68.11V413.7h-61.67l-3.36-26.16q-30.55,30.82-81.57,30.83-53.25,0-86.19-35.74T3887.43,285.46Zm68.11,4.91q0,38.53,17.8,60.14t50.59,21.61q41.76,0,61.11-31.07V233.14q-18.78-30.36-60.55-30.37-33.07,0-51,21.84T3955.54,290.37Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#1c991c"
          d="M4379.85,345q0-15.18-15-23.12t-49.76-14q-34.76-6.06-58-15.41-51-20.55-51-59.56,0-32.7,33.07-54.66t84.09-22q54.38,0,87.87,22.43t33.5,58.16h-68.11q0-16.35-14.58-27.22t-38.68-10.86q-22.42,0-36.58,8.65t-14.15,23.12q0,13.08,13.17,20.32t53.26,14.6q40.08,7.36,62.92,17.52t33.92,24.41q11.07,14.25,11.07,34.57,0,34.1-33.91,55.24t-88.86,21.14q-37.27,0-66.43-11.21t-45.41-30.84Q4196,356.7,4196,334.05h66.15q1.4,20.08,18.22,30.95t44.56,10.86q26.91,0,40.93-8.53T4379.85,345Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#30b24f"
          d="M1431.76,95.33a27.57,27.57,0,0,1,10-21.73q9.94-8.62,28.45-8.64t28.59,8.64a27.42,27.42,0,0,1,10.09,21.73,26.82,26.82,0,0,1-10.09,21.37q-10.1,8.53-28.59,8.53t-28.45-8.53A27,27,0,0,1,1431.76,95.33Zm72.31,318.37H1436V161h68.11Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#30b24f"
          d="M1627.07,161l2,29.2q33.63-33.87,88.29-33.87,94.74,0,96.42,90.4v167h-68.11V250q0-24.06-12.47-35.62t-40.78-11.57q-41.2,0-61.39,31.07V413.7h-68.11V161Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#30b24f"
          d="M2082.72,99.53V161h53.53v42h-53.53V344.09q0,14.49,6.86,20.91t24.53,6.42a124.68,124.68,0,0,0,23.82-2.34V413a199.21,199.21,0,0,1-44.84,5.37q-78.48,0-78.49-72.18V203h-49.89V161h49.89V99.53Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#30b24f"
          d="M2241,188.53q33.34-32.24,84.37-32.24,97,0,98.38,92.27V413.7h-68.11v-163q0-26.16-13.6-37t-39.94-10.87q-40.92,0-61.1,30.37V413.7h-68.11V54.92H2241Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#30b24f"
          d="M2611.8,418.37q-64.74,0-105-34t-40.22-90.51v-7q0-37.83,17.52-67.62t49.19-46.36Q2565,156.29,2604,156.29q62,0,95.73,32.94t33.77,93.19v22.9H2535.28q3.09,31.29,25.09,49.51t55.36,18.22q46.8,0,76.24-31.53l36.72,29.2q-18.22,22.65-48.63,35.15T2611.8,418.37Zm-8.13-216.53q-28,0-45.26,16.35t-22,45.55h129.78v-4.21Q2663.93,231,2648,216.44T2603.67,201.84Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#30b24f"
          d="M3167,335.45,3215.17,161h66.43L3198.91,413.7h-56.06l-65-173.55-63.9,173.55h-56.06l-83-252.74h66.43l49,172.62L3052.6,161h51.29Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#30b24f"
          d="M3446.35,418.37q-64.74,0-105-34t-40.22-90.51v-7q0-37.83,17.52-67.62t49.19-46.36q31.68-16.59,70.64-16.59,61.93,0,95.72,32.94T3568,282.42v22.9H3369.83q3.09,31.29,25.09,49.51t55.36,18.22q46.8,0,76.24-31.53l36.72,29.2q-18.22,22.65-48.63,35.15T3446.35,418.37Zm-8.12-216.53q-28,0-45.27,16.35t-22,45.55h129.77v-4.21q-2.25-28.48-18.22-43.09T3438.23,201.84Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#30b24f"
          d="M3740.05,418.37q-64.76,0-105-34t-40.22-90.51v-7q0-37.83,17.51-67.62t49.2-46.36q31.66-16.59,70.63-16.59,62,0,95.72,32.94t33.78,93.19v22.9H3663.53q3.07,31.29,25.08,49.51T3744,373.05q46.82,0,76.24-31.53l36.72,29.2q-18.22,22.65-48.63,35.15T3740.05,418.37Zm-8.13-216.53q-28,0-45.27,16.35t-22,45.55h129.78v-4.21q-2.25-28.48-18.22-43.09T3731.92,201.84Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#30b24f"
          d="M3887.43,285.46q0-58.39,32.51-93.78t87.18-35.39q48.2,0,77.92,28V54.92h68.11V413.7h-61.67l-3.36-26.16q-30.55,30.82-81.57,30.83-53.25,0-86.19-35.74T3887.43,285.46Zm68.11,4.91q0,38.53,17.8,60.14t50.59,21.61q41.76,0,61.11-31.07V233.14q-18.78-30.36-60.55-30.37-33.07,0-51,21.84T3955.54,290.37Z"
          transform="translate(0 -54.92)"
        />
        <path
          fill="#30b24f"
          d="M4379.85,345q0-15.18-15-23.12t-49.76-14q-34.76-6.06-58-15.41-51-20.55-51-59.56,0-32.7,33.07-54.66t84.09-22q54.38,0,87.87,22.43t33.5,58.16h-68.11q0-16.35-14.58-27.22t-38.68-10.86q-22.42,0-36.58,8.65t-14.15,23.12q0,13.08,13.17,20.32t53.26,14.6q40.08,7.36,62.92,17.52t33.92,24.41q11.07,14.25,11.07,34.57,0,34.1-33.91,55.24t-88.86,21.14q-37.27,0-66.43-11.21t-45.41-30.84Q4196,356.7,4196,334.05h66.15q1.4,20.08,18.22,30.95t44.56,10.86q26.91,0,40.93-8.53T4379.85,345Z"
          transform="translate(0 -54.92)"
        />
      </g>
    </svg>
  );
}
